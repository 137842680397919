<template>
  <b-sidebar
    id="sidebar-lead-handler"
    sidebar-class="sidebar-lg"
    @shown="openSidebar()"
    :visible="isLeadHandlerSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="
      (val) => {
        $emit('update:is-lead-handler-sidebar-active', val), (this.newActivity = false);
      }
    "
    @hidden="clearLeadData()"
  >
    <div class="overlay" v-if="isLoading">
      <div class="spinner"></div>
    </div>

    <!-- Header -->
    <div v-else>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 v-if="leadData.id" class="mb-0">Update Lead - {{ leadData.firstName }} {{ leadData.lastName }}</h5>

        <h5 v-else class="mb-0">
          Add Lead
        </h5>
        <div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeSidebar(false)" />
        </div>
      </div>

      <b-tabs class="mt-2" fill>
        <b-tab title="Form" active>
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver" style="position: relative;">
            <!-- Form -->
            <b-form @submit.prevent="handleSubmit(saveLead)">
              <div class="mt-2">
                <b-col cols="12" class="mb-1">
                  <validation-provider name="Developer Team" #default="{ errors }" rules="required">
                    <div class="d-flex align-items-center">
                      <label class="mr-1 w-25">Team</label>
                      <div class="w-75">
                        <v-select :disabled="!$Can('leads_edit')" v-model="leadData.team" :options="teams" label="title" :reduce="(val) => val.id" class="small-v-select" :clearable="false" placeholder="Choose a Team"></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <validation-provider name="Assigned User" #default="{ errors }" rules="required">
                    <div class="d-flex align-items-center">
                      <label class="mr-1 w-25">User</label>
                      <div class="w-75">
                        <v-select :disabled="!$Can('leads_edit')" v-model="leadData.assignedUser" :taggable="true" :options="users" label="value" class="small-v-select" :value="leadData.assignedUser" :reduce="(val) => val.assignedUser" :clearable="false" placeholder="Choose a User"> </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>

                <!-- name -->
                <b-col cols="12" class="mb-1" v-if="leadData.id">
                  <validation-provider name="Name" #default="{ errors }" rules="required">
                    <div class="d-flex align-items-center">
                      <label class="mr-1 w-25">Contact</label>
                      <div class="w-75">
                        <b-form-input :disabled="!$Can('leads_edit')" v-model="leadData.firstName" id="h-name" placeholder="Name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>

                <b-col cols="12" class="mb-1" v-else>
                  <div class="d-flex align-items-center">
                    <label class="mr-1 w-25">Contact</label>
                    <div class="w-75">
                      <v-select :disabled="!$Can('leads_edit')" v-model="selectedContact" label="fullName" :options="paginated" class="small-v-select  " :clearable="true" :filterable="false" @open="onOpen" @close="onClose" @search="(query) => (search = query)" placeholder="Select a Contact">
                        <template #list-footer>
                          <li v-show="hasNextPage" ref="load" class="loader">
                            Loading more options...
                          </li>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <validation-provider name="First Name" #default="{ errors }" rules="required">
                    <div class="d-flex align-items-center">
                      <label class="mr-1 w-25">First Name</label>
                      <div class="w-75">
                        <b-form-input :disabled="!$Can('leads_edit')" v-model="leadData.firstName" id="d-email" placeholder="First Name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <div class="d-flex align-items-center">
                    <label class="mr-1 w-25">Last Name</label>
                    <div class="w-75">
                      <b-form-input :disabled="!$Can('leads_edit')" v-model="leadData.lastName" id="d-email" placeholder="Last Name" />
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <validation-provider name="Email" #default="{ errors }" rules="email">
                    <div class="d-flex align-items-center">
                      <label class="mr-1 w-25">Email</label>
                      <div class="w-75">
                        <b-form-input :disabled="!$Can('leads_edit')" v-model="leadData.email" id="d-email" placeholder="Email" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <div class="d-flex align-items-center">
                    <label class="mr-1 w-25">Phone</label>
                    <b-form-input :disabled="!$Can('leads_edit')" v-model="leadData.phone" id="d-phone" class="w-75" placeholder="Phone" />
                  </div>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <div class="d-flex align-items-center">
                    <label class="mr-1 w-25">Location</label>
                    <b-form-input :disabled="!$Can('leads_edit')" v-model="leadData.location" id="d-location" class="w-75" placeholder="Location" />
                  </div>
                </b-col>

                <!-- Preferred Contact Section -->
                <b-col cols="12" class="mb-1" v-if="leadData.id">
                  <div class="d-flex align-items-center">
                    <label class="mr-1 w-25">Section</label>
                    <v-select :disabled="!$Can('leads_edit')" v-model="leadData.section" :options="leadFilters" label="title" class="small-v-select w-75" :reduce="(val) => val.title" :clearable="false"> </v-select>
                  </div>
                </b-col>

                <b-col cols="12" class="mb-1" v-if="leadData.id">
                  <div class="d-flex align-items-center">
                    <label class="mr-1 w-25">Status</label>
                    <v-select :disabled="!$Can('leads_edit')" v-model="leadData.leadStatus" :options="leadStatuses" label="title" class="small-v-select w-75" :reduce="(val) => val.title" :clearable="false"> </v-select>
                  </div>
                </b-col>

                <!-- Description -->
                <b-col cols="12" class="mb-1" v-if="!leadData.id">
                  <quill-editor id="quil-content" v-model="leadData.message" :options="editorOption" />
                  <div id="quill-toolbar" class="d-flex justify-content-end">
                    <button class="ql-bold"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-background" value="#fdff32"></button>
                    <button class="ql-link"></button>
                  </div>
                </b-col>

                <b-col cols="12" class="mb-1" v-else>
                  <b-textarea :disabled="!$Can('leads_edit')" readonly :value="sanitizeHTML(leadData.message)" rows="7"> </b-textarea>
                </b-col>
              </div>

              <!-- Form Actions -->
              <div class="d-flex justify-content-between mt-2 mb-5" v-if="leadData.id && leadData.projectId == null">
                <div>
                  <b-button v-if="$Can('leads_add_project')" variant="warning" class="ml-2" v-b-modal.modal-create-project>
                    Create Project
                  </b-button>
                </div>
                <div>
                  <b-button v-if="$Can('leads_edit')" variant="primary" class="mr-2" type="submit">
                    Update
                  </b-button>
                </div>
              </div>
              <div v-else class="d-flex justify-content-end mt-2 mb-5">
                <b-button v-if="leadData.id" v-show="$Can('leads_edit')" variant="primary" class="mr-2" type="submit">
                  Update
                </b-button>

                <b-button v-else variant="primary" class="mr-2" type="submit">
                  Add
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-tab>

        <b-tab title="Notes" v-if="leadData.id && $Can('leads_notes')">
          <vue-perfect-scrollbar v-if="comments.length > 0" :settings="perfectScrollbarSettings" class="scroll-area" :style="{ height: scrollAreaHeight + 'px' }">
            <b-overlay :show="commentLoading" rounded="sm">
              <div id="comment-section">
                <div v-if="moreCommentsAvailable" class="text-center mb-2">
                  <a @click="showMoreComments" class="more-comments-link">{{ comments.length - 3 }} more Notes</a>
                </div>

                <div v-if="!moreCommentsAvailable && comments.length > 3" class="text-center mb-2">
                  <a @click="visibleCommentsCount = 3" class="more-comments-link">Hide Earlier Notes</a>
                </div>

                <div class="comment" v-for="comment in visibleComments" :key="comment.id">
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>
                      <div style="cursor:pointer" v-if="comment.isImportant">
                        👍 <span>{{ comment.full_name }}</span>
                      </div>
                      <div style="cursor:pointer" v-else>
                        👎 <span>{{ comment.full_name }}</span>
                      </div>
                    </h6>
                    <small class="text-muted" v-b-tooltip.hover.top="formatTimeAgo(comment.created_at, comment.updated_at)">{{ formatDateTime(comment.created_at) }}</small>
                  </div>

                  <div class="edit-comment" @click="editCommentOpen(comment)" v-if="!comment.isEditing"><span v-html="comment.comment"> </span></div>

                  <div v-else>
                    <quill-editor id="quil-content" style="background-color: white;" v-model="comment.comment" :options="commentOption" />

                    <div id="comment-toolbar" class="d-flex justify-content-end">
                      <button class="ql-bold"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-background" value="#fdff32"></button>
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <button class="ql-link"></button>

                      <label class="toggle thumbs-up-down">
                        <input type="checkbox" v-model="comment.isImportant" class="toggle-checkbox" />
                        <div class="toggle-btn" v-if="comment.isImportant" v-b-tooltip.hover.top="'Important'"></div>
                        <div class="toggle-btn" v-else v-b-tooltip.hover.top="'Unimportant'"></div>
                      </label>

                      <b-button v-if="comment.comment != null && comment.comment != ''" @click="editComment(comment.id, comment.comment, comment.isImportant)" variant="primary" style="width: 60px;">
                        Update
                      </b-button>

                      <b-button v-if="comment.comment != null && comment.comment != ''" @click="cancelComment()" variant="danger" class="ml-25" style="width: 60px;">
                        Cancel
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
          </vue-perfect-scrollbar>

          <div v-else id="comment-section" class="no-comment">
            <p class="m-2">No Notes Yet</p>
          </div>

          <div class="fix-comment">
            <quill-editor id="quil-content" style="background-color: white;" v-model="tempComment" :options="commentOption" />

            <div id="comment-toolbar" class="d-flex justify-content-end">
              <button class="ql-bold"></button>

              <button class="ql-underline"></button>
              <button class="ql-background" value="#fdff32"></button>
              <button class="ql-link"></button>

              <div class="d-flex align-items-center" v-if="tempComment != null && tempComment != ''">
                <label class="toggle thumbs-up-down">
                  <input type="checkbox" v-model="isImportant" class="toggle-checkbox" />

                  <div class="toggle-btn" v-if="isImportant" v-b-tooltip.hover.top="'Important'"></div>
                  <div class="toggle-btn" v-else v-b-tooltip.hover.top="'Unimportant'"></div>
                </label>

                <b-button @click="saveComment(leadData.id, isImportant)" variant="primary" style="width: 72px;">
                  Note
                </b-button>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="All activity" v-if="leadData.id && $Can('leads_activity')">
          <vue-perfect-scrollbar v-if="activities.length > 0" :settings="perfectScrollbarSettings" class="scroll-area" :style="{ height: scrollAreaHeight + 60 + 'px' }">
            <b-overlay :show="activityLoading" rounded="sm">
              <div class="activity" id="activity-section" v-for="activity in activities" :key="activity.id" style="z-index:-1" :variant="getShortVariant(activity.title)">
                <b-card>
                  <!-- <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <strong> <img v-if="activity.meetingProvider" :src="getLogo(activity.meetingProvider)" alt="Logo" :class="activity.meetingProvider == 'Teams' ? 'logo-teams' : 'logo-zoom'" /> {{ getShortDescription(activity.title) }} </strong>
                    <small class="text-muted" v-b-tooltip.hover.top="formatTimeAgo(activity.created_at, activity.updated_at)">{{ activity.userName }} - {{ formatDateTime(activity.created_at) }}</small>
                  </div> -->
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <strong> <img v-if="activity.meetingProvider" :src="getLogo(activity.meetingProvider)" alt="Logo" :class="activity.meetingProvider == 'Teams' ? 'logo-teams' : 'logo-zoom'" /> {{ getShortDescription(activity.title) }} </strong>

                    <small class="text-right" v-b-tooltip.hover.top="formatTimeAgo(activity.created_at, activity.updated_at)">
                      <div>{{ activity.userName }}</div>
                      <div class="text-muted">{{ formatDateTime(activity.created_at) }}</div>
                    </small>
                  </div>

                  <div style="text-align:center" class="mt-1 mb-sm-0" :class="activity.meetingProvider == 'Teams' ? 'color-teams' : 'color-zoom'" v-if="activity.title == 'Meeting'">
                    <b-card :class="activity.meetingProvider == 'Teams' ? 'border-teams' : 'border-zoom'">
                      <strong>{{ formatDateTime(activity.titleTime) }} to {{ formatDateTime(activity.meetingTime) }}</strong> <br />
                      <strong v-if="activity.cancelled" class="text-danger">
                        Cancelled
                      </strong>
                    </b-card>
                  </div>

                  <div style="text-align:justify" class="mt-1 mb-sm-0 p-75" v-if="activity.description !== '' && activity.description !== null">
                    <b-card class="border-secondary">
                      <u class="text-muted text-center d-block" style="font-weight: 600;">Activity Notes </u>

                      <div v-if="activity.isEditingNotes">
                        <b-textarea v-model="activity.description" rows="3" class="mt-1"></b-textarea>
                        <button class="btn btn-sm btn-outline-primary" @click="toggleEditingMessage(activity)">Save</button>
                      </div>

                      <div v-else @dblclick="toggleEditingMessage(activity)">
                        <div v-if="!activity.isOpening && activity.description.length < 61">
                          {{ activity.description }}
                        </div>

                        <div v-if="!activity.isOpening && activity.description.length > 60">
                          {{ getTruncatedDescription(activity.description) }}
                          <div v-if="activity.description.length > 60" class="d-flex align-items-center justify-content-end">
                            <button class="btn btn-link" @click="toggleDescription(activity)">Read More</button>
                          </div>
                        </div>

                        <div style="overflow-wrap: break-word;" v-if="activity.isOpening && activity.description.length > 60">
                          {{ activity.description }}

                          <div class="d-flex align-items-center justify-content-end">
                            <button class="btn btn-link" @click="toggleDescription(activity)">Read Less</button>
                          </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-start">
                          <!-- <button class="btn btn-sm btn-outline-primary" @click="toggleEditingMessage(activity)">Edit</button> -->
                        </div>
                      </div>
                    </b-card>

                    <!-- <div v-if="activity.description.length > 60">
                  <button class="btn btn-link" @click="toggleDescription(activity)">{{ activity.isOpening ? 'Read More' : 'Read Less' }}</button>
                </div> -->
                  </div>

                  <div style="text-align:center" class="mt-1 mb-sm-0 p-75  bg-white text-white" v-if="activity.attendingList.length > 0">
                    <b-card class="border-primary">
                      <span class="justify-content-left"
                        ><b-badge pill small variant="primary" class="badge-glow" v-for="(user, index) in activity.attendingList" :key="index">{{ user }}</b-badge>
                      </span>
                    </b-card>
                  </div>

                  <div style="text-align:right" class="mt-1 mb-sm-0 p-75  bg-white text-white" v-if="activity.title == 'Meeting' && !activity.cancelled">
                    <span class="justify-content-left">
                      <b-button size="sm" :class="activity.meetingProvider == 'Teams' ? 'button-teams' : 'button-zoom'" @click="joinMeeting(activity)">Join</b-button>
                      <b-button variant="danger" size="sm" @click="cancelMeeting(activity)">Cancel</b-button>
                    </span>
                  </div>

                  <div style="text-align:center" class="mt-1 mb-sm-0 p-75  bg-white" v-if="activity.reminders.length > 0">
                    <small class="justify-content-left" v-for="(reminder, index) in activity.reminders" :key="index"
                      >A reminder for this activity has been set for <strong>{{ convertTimeFormat(reminder.reminderDate) }}</strong> </small
                    ><br />
                  </div>
                </b-card>
              </div>
            </b-overlay>
          </vue-perfect-scrollbar>

          <div v-else id="activity-section" class="no-comment">
            <p class="m-2">No Activity Yet</p>
          </div>

          <div class="fix-comment" v-if="newActivity">
            <div class="close-button" @click="newActivity = false" style="cursor: pointer; position: absolute; top: 0px; right: 10px;">
              <span style="font-size: 1.5em; color:red">&times;</span>
            </div>
            <b-row class="">
              <b-col cols="6">
                <!-- <v-select v-model="selectedOption" :options="options" /> -->
                <multiselect v-model="selectedOption" :options="options" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select activity">
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option }}</span>
                    </div>
                  </template>
                </multiselect>
              </b-col>
              <b-col cols="6">
                <b-form-group v-if="selectedOption == 'Meeting'" class="mt-1">
                  <b-form-checkbox v-model="includeClient">Include Client</b-form-checkbox>
                </b-form-group>

                <flat-pickr
                  v-else
                  v-model="dateNtim"
                  class="form-control"
                  :placeholder="(selectedOption ? selectedOption : '') + ' Time'"
                  style="background-color: white;"
                  :config="{
                    minuteIncrement: 1,
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: 'd/m/Y H:i',
                    locale: {
                      firstDayOfWeek: 1,
                    },
                  }"
                />
              </b-col>
            </b-row>

            <b-row v-if="selectedOption == 'Meeting'" class="meeting-row">
              <b-col cols="6">
                <b-form-group :state="dateNtim ? true : false" invalid-feedback="Starting Time is required">
                  <flat-pickr
                    v-model="dateNtim"
                    class="form-control"
                    :placeholder="(selectedOption ? 'Starting' : '') + ' Time'"
                    style="background-color: white;"
                    :config="{
                      minuteIncrement: 1,
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: 'd/m/Y H:i',
                      locale: {
                        firstDayOfWeek: 1,
                      },
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group :state="meetingDateNtim ? true : false" invalid-feedback="Ending Time is required">
                  <flat-pickr
                    placeholder="Ending Time"
                    v-model="meetingDateNtim"
                    class="form-control"
                    style="background-color: white;"
                    :config="{
                      minuteIncrement: 1,
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: 'd/m/Y H:i',
                      // defaultDate: new Date(),
                      minDate: dateNtim,
                      locale: {
                        firstDayOfWeek: 1,
                      },
                    }"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="selectedOption == 'Meeting'" class="meeting-row">
              <b-col cols="6">
                <multiselect v-model="multiSelectUsers" :limit="1" :options="users" :searchable="false" label="value" track-by="assignedUser" :multiple="true" :close-on-select="false" :show-labels="false" placeholder="Select"> </multiselect>
              </b-col>

              <b-col cols="6" class="d-flex align-items-center">
                <validation-provider name="Email" #default="{ errors }" rules="email">
                  <b-form-input v-model="newEmail" placeholder="Add"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <feather-icon icon="PlusIcon" class="cursor-pointer text-primary" size="36" @click="addEmail" />
              </b-col>
            </b-row>

            <b-row v-if="(selectedOption == 'Meeting' && (this.multiSelectUsers.length > 0 || this.emailList.length > 0)) || this.includeClient" class="meeting-row">
              <b-col class="d-flex justify-content-center">
                <b-card class="outline-secondary mt-2 w-100 font-50 attend-list">
                  <strong>Attendee List</strong> <br />

                  <small v-if="includeClient"> {{ leadData.email }}, </small>

                  <small v-for="(name, index) in multiSelectUsers" :key="index"> {{ name.mail ? name.mail : name.value }} </small>

                  <span v-for="(email, index) in emailList" :key="index">
                    <small> {{ email }} <feather-icon icon="XIcon" style="color:red" class="cursor-pointer" size="16" @click="removeEmail(email)" /> </small>
                  </span>
                </b-card>
              </b-col>
            </b-row>

            <b-row v-if="selectedOption == 'Meeting'" class="meeting-row">
              <b-col cols="12">
                <b-form-group class="mt-1">
                  <b-form-radio-group v-model="provider" name="provider" class="d-flex flex-row justify-content-center">
                    <RadioOption v-for="(option, index) in providerOptions" :key="index" :value="option.value" :text="option.text" :logo-src="option.logoSrc" />
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="meeting-row mt-1 mb-1" v-if="selectedOption">
              <b-col cols="6">
                <b-form-group class="d-flex align-items-left text-left justify-content-start">
                  <span class="meeting-label">
                    <b-form-checkbox id="checkbox-1" v-model="setReminder" name="checkbox-1">
                      Remind me at:
                    </b-form-checkbox>
                  </span>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group>
                  <flat-pickr
                    v-model="reminderDate"
                    class="form-control"
                    placeholder="Reminder Time"
                    style="background-color: white;"
                    :config="{
                      minuteIncrement: 60,
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: 'd/m/Y H:00',
                      minDate: 'today',
                      locale: {
                        firstDayOfWeek: 1,
                      },
                    }"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="meeting-row">
              <b-col cols="12">
                <b-textarea v-model="description" rows="3" :placeholder="(selectedOption ? selectedOption : '') + ' Notes'" class="mt-1"></b-textarea>
              </b-col>
            </b-row>

            <b-button block size="sm" class="mt-25" @click="saveActivity(leadData.id)" variant="success">Save Activity</b-button>
          </div>

          <div class="fix-comment" v-else>
            <b-button v-if="$Can('leads_new_activity')" block variant="primary" size="sm" @click="newActivity = true">New Activity</b-button>
          </div>
        </b-tab>

        <b-tab title="Files" v-if="leadData.id && $Can('leads_files')">
          <div id="files-section" v-if="leadData != [] && leadData.get_files.length > 0">
            <b-card>
              <span v-for="item in leadData.get_files" :key="item.id">
                <a style="text-decoration: none;" target="_blank" :href="leadFileURL + item.fileLink">
                  <b-img :src="require('@/assets/images/file.png')" height="auto" width="20" class="mr-1" style="float: left;" />
                  <!-- <small>{{ dateFormat(item.created_at) }}</small> -->

                  <span style="font-size: 0.8em;" :href="leadFileURL + item.fileLink">{{ item.status == 'A' ? '⭐' : '' }} {{ item.fileName }}</span>
                </a>
                <b-button v-if="$Can('leads_files_remove')" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(item)" class="btn-icon ml-auto float-right" style="padding: 1% 1%;" size="sm">
                  <feather-icon icon="XIcon" />
                </b-button>

                <hr />
              </span>
            </b-card>
          </div>

          <div v-else id="files-section" class="no-comment">
            <p class="m-2">No Files Yet</p>
          </div>

          <div class="fix-comment" v-if="newFile">
            <div class="close-button" @click="newFile = false" style="cursor: pointer; position: absolute; top: 0px; right: 10px;">
              <span style="font-size: 1.5em; color:red">&times;</span>
            </div>
            <b-row class="">
              <b-col cols="12">
                <!-- <v-select v-model="selectedOption" :options="options" /> -->

                <b-form ref="fileRules">
                  <b-form-group :state="modalFileName ? true : false" invalid-feedback="File Name is required">
                    <label for="fileName">File Name:</label>
                    <b-form-input id="fileName" type="text" :state="modalFileName ? true : false" v-model="modalFileName" required placeholder="File Name" />
                  </b-form-group>
                  <b-form-group :state="modalFile ? true : false" invalid-feedback="File is required">
                    <b-form-file @input="fileSelected" type="file" :state="modalFile ? true : false" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                  </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                  <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
                <b-col>
                  <b-button block size="sm" class="mt-25" @click="saveFile(leadData.id)" variant="success">Save File</b-button>
                </b-col>
              </b-col>
            </b-row>
          </div>

          <div class="fix-comment" v-else>
            <b-button block variant="primary" size="sm" @click="newFile = true">New File</b-button>
          </div>
        </b-tab>

        <b-tab title="Times" v-if="leadData.id">
          <div id="time-section">
            <ul class="country-list">
              <div class="mb-1">
                <b-row>
                  <b-col cols="4">
                    <flat-pickr v-model="selectedTime" class="form-control flatpickr-sm" :config="{ enableTime: true, noCalendar: true, time_24hr: true, dateFormat: 'H:i' }" />
                  </b-col>

                  <b-col cols="8">
                    <v-select v-model="selectedTimezone" :clearable="false" label="timezone" :options="timezones" class="select-size-sm" />
                  </b-col>
                </b-row>
              </div>

              <li v-for="(country, index) in countries" :key="country.name" class="country-item" :class="{ 'dark-bg': index % 2 === 0, 'light-bg': index % 2 !== 0 }">
                <span class="country-name">{{ country.name }}</span>
                <span class="country-time">{{ country.localTime }}</span>
                <span class="country-timezone">{{ country.timezone }}</span>
              </li>
            </ul>
          </div>
        </b-tab>
      </b-tabs>
      <b-modal id="modal-create-project" @ok="createProject" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save Project" centered title="New Project">
        <validation-observer ref="projectRules">
          <b-form>
            <b-form-group>
              <label for="projectName">Project Name:</label>
              <validation-provider name="Project Name" #default="{ errors }" rules="required">
                <b-form-input id="projectName" type="text" v-model="modalProjectName" placeholder="Project Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label for="client">Client:</label>

              <v-select v-model="modalClient" :options="clients" label="name" :clearable="true" placeholder="Choose a Client"></v-select>
            </b-form-group>

            <b-form-group>
              <label for="clientName">Client Name:</label>
              <validation-provider name="Client Name" #default="{ errors }" rules="required">
                <b-form-input id="clientName" type="text" v-model="modalClientName" placeholder="Client Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BTabs, BTab, VBTooltip, BBadge } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, url } from '@validations';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import 'moment-timezone';

import axiosIns from '@/libs/axios';
import RadioOption from './RadioOption.vue';
import { getShortDescription, getShortVariant, getTruncatedDescription, formatTimeAgo, formatDateTime } from '../helpers';

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BBadge,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    VuePerfectScrollbar,
    AppTimeline,
    AppTimelineItem,
    Multiselect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    RadioOption,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isLeadHandlerSidebarActive',
    event: 'update:is-lead-handler-sidebar-active',
  },
  props: {
    isLeadHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },

    leadData: {
      type: Object,
      required: false,
    },

    blankLead: {
      type: Object,
      required: true,
    },
    leadFilters: {
      type: Array,
      required: false,
    },
  },

  data() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    };

    const commentOption = {
      modules: {
        toolbar: '#comment-toolbar',
      },
      placeholder: 'Add a note',
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      isImportant: false,
      selectedTime: null,
      selectedTimezone: 'Europe/London',
      timezones: moment.tz.names(),
      countries: [
        { name: 'United Kingdom', localTime: '', timezone: 'Europe/London' },
        { name: 'Turkey', timezone: 'Europe/Istanbul' },
        { name: 'United States', timezone: 'America/New_York' },
        { name: 'Canada', timezone: 'America/Toronto' },
        { name: 'Germany', timezone: 'Europe/Berlin' },
        { name: 'France', timezone: 'Europe/Paris' },
        { name: 'Australia', timezone: 'Australia/Sydney' },
        { name: 'Japan', timezone: 'Asia/Tokyo' },
        { name: 'India', timezone: 'Asia/Kolkata' },
        { name: 'Brazil', timezone: 'America/Sao_Paulo' },
        { name: 'China', timezone: 'Asia/Shanghai' },
        { name: 'South Korea', timezone: 'Asia/Seoul' },
        { name: 'Russia', timezone: 'Europe/Moscow' },
        { name: 'South Africa', timezone: 'Africa/Johannesburg' },
        { name: 'Mexico', timezone: 'America/Mexico_City' },
        { name: 'Italy', timezone: 'Europe/Rome' },
        { name: 'Spain', timezone: 'Europe/Madrid' },
        { name: 'Netherlands', timezone: 'Europe/Amsterdam' },
        { name: 'Sweden', timezone: 'Europe/Stockholm' },
      ],

      leadStatuses: [],
      editorOption,
      commentOption,
      setReminder: false,
      reminderDate: null,

      required,
      email,
      url,
      methods: ['PHONE', 'EMAIL', 'SMS', 'LETTER', 'WEBSITE'],
      tempComment: null,
      visibleCommentsCount: 3,
      showCommentToolbar: false,
      teams: [
        {
          title: 'EXCEL TEAM',
          id: 'EXCEL',
        },
        {
          title: 'WEB TEAM',
          id: 'WEB',
        },
        {
          title: 'OTHER',
          id: 'OTHER',
        },
      ],
      users: [],
      comments: [],
      activities: [],

      commentLoading: true,
      activityLoading: true,
      leadFileURL: store.state.app.leadFileURL,
      perfectScrollbarSettings,
      scrollAreaHeight: 500,
      selectedContact: null,
      contacts: [],
      search: '',
      observer: null,
      limit: 10,
      userData: JSON.parse(localStorage.getItem('userData')),
      dateNtim: null,
      meetingDateNtim: null,
      provider: 'Teams',
      providerOptions: [
        {
          value: 'Teams',
          text: 'Teams',
          logoSrc: require('@/assets/images/logo/teams.svg'),
        },
        {
          value: 'Zoom',
          text: 'Zoom',
          logoSrc: require('@/assets/images/logo/zoom.svg'),
        },
      ],
      isLoading: false,

      newEmail: null,
      emailList: [],

      multiSelectUsers: [],
      includeClient: false,

      selectedOption: null,
      options: ['Call', 'Mail', 'Check', 'Meeting', 'Quote', 'Invoice'],
      newActivity: false,
      description: null,
      newFile: false,

      modalFileName: null,
      modalFile: null,
      percentCompleted: 0,

      modalProjectName: null,
      modalClient: null,
      modalClientName: null,
      clients: [],
      projectData: {
        name: null,
        client: null,
        startDate: null,
        team: null,
        logo: 'placeholder.jpg',
        taskList: [],
      },
    };
  },

  created() {
    this.getUsers();
    this.listStatus();
  },

  computed: {
    visibleComments() {
      return this.comments.slice(0, this.visibleCommentsCount).reverse();
    },
    moreCommentsAvailable() {
      return this.comments.length > this.visibleCommentsCount;
    },

    filtered() {
      const search = this.search || '';
      return this.contacts.filter((contact) => (contact.firstName || '').toLowerCase().includes(search.toLowerCase()) || (contact.lastName || '').toLowerCase().includes(search.toLowerCase()));
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },

  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.selectedTime = this.getCurrentTime();
  },

  methods: {
    getShortDescription,
    getShortVariant,
    getTruncatedDescription,
    formatTimeAgo,
    formatDateTime,

    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    },

    calculateTimes() {
      const londonTime = moment.tz(this.selectedTime, 'HH:mm', this.selectedTimezone);

      this.countries.forEach((country) => {
        const localTime = londonTime
          .clone()
          .tz(country.timezone)
          .format('HH:mm');
        country.localTime = localTime;
      });
    },

    getUsers() {
      store
        .dispatch('lead/fetchUsers', [])
        .then((res) => {
          res.data.user.forEach((element) => {
            if (element != null) {
              this.users.push({
                assignedUser: Number(element.id),
                value: String(element.name + ' ' + element.surname),
                mail: element.email,
              });
            }
          });
          this.users.push({
            assignedUser: Number(0),
            value: 'All Users',
          });

          this.users = this.users.reverse();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    listStatus() {
      this.loading = true;
      store
        .dispatch('lead/listStatus')
        .then((res) => {
          this.leadStatuses = [];

          res.data.forEach((element, index) => {
            this.leadStatuses.push({ title: element.title, textColor: element.text_color, bgColor: element.bg_color, icon: 'MenuIcon', route: { name: 'leads-dashboard-status', params: { status: element.title } } });
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });

          console.log(error);
        });
    },

    parseDate(inputTime) {
      // Split the inputTime string into date and time parts
      const [datePart, timePart] = inputTime.split(' ');

      // Split the date part into day, month, and year
      const [day, month, year] = datePart.split('/');

      // Split the time part into hours and minutes
      const [hours, minutes] = timePart.split(':');

      // Create a new Date object using the recognized format "YYYY-MM-DDTHH:mm"
      const isoDate = `${year}-${month}-${day}T${hours}:${minutes}`;

      const date = new Date(isoDate);

      return date;
    },

    convertTimeFormat(inputTime) {
      // Parse the input time string
      const date = this.parseDate(inputTime);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' }); // Get the month name
      const year = date.getFullYear();

      // Get the hour and minute components
      const hour = date.getHours();
      const minute = date.getMinutes();

      // Determine if it's AM or PM
      const period = hour < 12 ? 'am' : 'pm';

      // Convert 24-hour format to 12-hour format
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

      // Create the non-numeric time string
      const formattedTime = `${day} ${month} ${year} ${formattedHour}${period}`;

      return formattedTime;
    },

    editCommentOpen(comment) {
      if (comment.userId == this.userData.id) {
        this.comments.forEach((comment) => {
          comment.isEditing = false;
        });
        comment.isEditing = true;
      }
    },

    cancelComment() {
      this.comments.forEach((comment) => {
        comment.isEditing = false;
      });
    },

    calculateScrollAreaHeight(val) {
      const commentTabContent = document.querySelector('#sidebar-lead-handler');

      if (commentTabContent) {
        this.scrollAreaHeight = commentTabContent.clientHeight - 250;
      }
    },

    clearLeadData() {
      this.$emit('update:lead-data', JSON.parse(JSON.stringify(this.blankLead)));

      this.$refs.refFormObserver.reset();

      this.visibleCommentsCount = 3;
    },

    closeSidebar(val) {
      this.newActivity = false;
      this.$emit('update:is-lead-handler-sidebar-active', val);
    },

    openSidebar(val) {
      if (this.leadData.id != null) {
        this.listComments();
        this.listActivity();
        this.listClients();
      } else {
        this.listContacts();
      }
    },

    listComments() {
      this.commentLoading = true;
      store
        .dispatch('lead/listLeadComments', { id: this.leadData.id })
        .then((res) => {
          this.comments = res.data;
          this.commentLoading = false;
          this.calculateScrollAreaHeight();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Comment list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });

          this.commentLoading = false;
        });
    },

    listActivity() {
      this.activityLoading = true;
      this.activities = [];
      store
        .dispatch('lead/listLeadActivity', { id: this.leadData.id })
        .then((res) => {
          this.activities = res.data;
          this.activityLoading = false;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Activity list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          });
          this.activityLoading = false;
        });
    },

    listContacts() {
      store
        .dispatch('lead/listContacts')
        .then((res) => {
          this.contacts = res.data;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Contacts list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    listClients() {
      store
        .dispatch('lead/listClients', { isArchive: 0 })
        .then((res) => {
          this.clients = res.data;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Client list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    showMoreComments() {
      this.visibleCommentsCount = this.comments.length;
      this.calculateScrollAreaHeight();
    },

    joinMeeting(activity) {
      const url = activity.joinLink; // Replace with your desired URL
      window.open(url, '_blank');
    },
    cancelMeeting(activity) {
      this.$swal({
        title: 'The meeting will be cancelled. Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel the meeting!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;

          store
            .dispatch('lead/cancelLeadMeeting', { activityId: activity.id })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  text: 'The meeeting has been cancelled successfully! 👍',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              });

              this.isLoading = false;
              this.listActivity();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: '❌ Please try again or report an issue to support.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
              console.log(error);
            });
        }
      });
    },

    saveLead() {
      if (this.leadData.id != null) {
        store.dispatch('lead/updateLead', this.leadData).then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Lead has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.$emit('update:is-lead-handler-sidebar-active', false);
        });
      } else {
        this.leadData.status = 'B';
        store
          .dispatch('lead/saveLead', this.leadData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Lead was added successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });

            this.$emit('update:is-lead-handler-sidebar-active', false);
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
    },

    saveComment(leadId, isImportant) {
      store
        .dispatch('lead/saveLeadComment', { leadId: leadId, comment: this.tempComment, isImportant: isImportant })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Lead comment was added successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.tempComment = null;
          this.isImportant = false;
          this.listComments();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    editComment(commentId, comment, isImportant) {
      store
        .dispatch('lead/editComment', { commentId: commentId, comment: comment, isImportant, isImportant })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Lead comment was edited successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.tempComment = null;

          this.listComments();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    addEmail() {
      if (this.newEmail && this.newEmail != this.leadData.email) {
        const multiExist = this.multiSelectUsers.find((e) => e.mail == this.newEmail);
        if (!this.emailList.includes(this.newEmail) && !multiExist) {
          this.emailList.push(this.newEmail);
        }
        this.newEmail = null;
      }
    },

    removeEmail(email) {
      this.emailList = this.emailList.filter((e) => e != email);
    },

    createProject() {
      this.projectData = {
        name: this.modalProjectName,
        client: this.modalClient,
        clientName: this.modalClientName,
        startDate: null,
        team: null,
        logo: 'placeholder.jpg',
        taskList: [],
        leadId: this.leadData.id,
      };

      this.loading = true;
      store
        .dispatch('lead/saveLeadProject', this.projectData)
        .then((res) => {
          this.loading = false;
          if (res.data.error == null) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been created',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            this.projectData = {
              name: null,
              client: null,
              clientName: null,
              startDate: null,
              team: null,
              logo: 'placeholder.jpg',
              taskList: [],
              leadId: null,
            };

            this.$router.push({ name: 'project-preview', params: { id: res.data.id } });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: res.data.error,
                icon: 'ThumbsUpIcon',
                variant: 'danger',
              },
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    resetModal() {
      this.modalProjectName = null;
      this.modalClient = null;
    },

    formatTimeAgo(dateTimeString, updatedDateTimeString) {
      const now = new Date();
      const date = new Date(dateTimeString);
      const updatedDate = new Date(updatedDateTimeString);
      const diffInMilliseconds = now - date;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInSeconds < 15) {
        return `Just now`;
      } else if (diffInSeconds >= 15 && diffInSeconds < 60) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago${edited}`;
      } else if (diffInMinutes < 60) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago${edited}`;
      } else if (diffInHours < 24) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago${edited}`;
      } else {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago${edited}`;
      }
    },

    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return dateTime.toLocaleDateString('en-UK', options);
    },

    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      if (this.selectedContact != null) {
        this.leadData.firstName = this.selectedContact.firstName;
        this.leadData.lastName = this.selectedContact.lastName;
        this.leadData.email = this.selectedContact.email;
        this.leadData.phone = this.selectedContact.phoneNumber;
      } else {
        this.leadData.firstName = null;
        this.leadData.lastName = null;
        this.leadData.email = null;
        this.leadData.phone = null;
      }

      this.limit = 10;
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    sanitizeHTML(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    },

    saveActivity(leadId) {
      if (this.selectedOption != null) {
        if (this.selectedOption != 'Meeting') {
          store
            .dispatch('lead/saveLeadActivity', { leadId: leadId, title: this.selectedOption, titleDate: this.dateNtim, meetingDate: this.meetingDateNtim, description: this.description, setReminder: this.setReminder, reminderDate: this.reminderDate })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  text: 'Lead activity was added successfully! 👍',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              });
              this.tempComment = null;
              this.dateNtim = null;
              this.dateTimeString = null;
              this.newActivity = false;
              this.selectedOption = null;
              this.description = null;
              (this.dateNtim = null), (this.meetingDateNtim = null), (this.multiSelectUsers = []), (this.setReminder = false), (this.reminderDate = null), this.listActivity();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: '❌ Please try again or report an issue to support.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
              console.log(error);
            });
        } else if (this.selectedOption == 'Meeting' && this.dateNtim != null && this.meetingDateNtim != null) {
          const attendeeList = this.multiSelectUsers.map((element) => element.mail || element.value);
          this.emailList.forEach((element) => attendeeList.push(element));
          if (this.includeClient) {
            attendeeList.push(this.leadData.email);
          }

          this.isLoading = true;
          store
            .dispatch('lead/saveLeadActivity', {
              leadId: leadId,
              clientName: this.leadData.firstName,
              title: this.selectedOption,
              titleDate: this.dateNtim,
              meetingDate: this.meetingDateNtim,
              provider: this.provider,
              description: this.description,
              userList: this.multiSelectUsers,
              attendeeList: attendeeList,
            })
            .then((response) => {
              if (response.data == 'ok') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    text: 'Lead activity was added successfully! 👍',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                });
                this.tempComment = null;
                this.dateNtim = null;
                this.dateTimeString = null;
                this.newActivity = false;
                this.selectedOption = null;
                this.description = null;
                (this.dateNtim = null), (this.meetingDateNtim = null), (this.provider = 'Teams'), (this.includeClient = false);
                this.emailList = [];
                (this.multiSelectUsers = []), (this.setReminder = false), (this.reminderDate = null), this.listActivity();
              } else if (response.data == 'Host Not Found') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    text: '❌ Your e-mail address is not associated with Company Microsoft Account',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                });
                this.listActivity();
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    text: '❌ Please try again or report an issue to support.',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                });
                this.listActivity();
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: '❌ Please try again or report an issue to support.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
              console.log(error);
            })
            .finally(() => {
              this.isLoading = false; // Set isLoading to false when the API call is completed
            });
        }
      }
    },

    getShortDescription(selectedOption) {
      const descriptions = {
        Call: '📞 Phone call made',
        Mail: '📧 Email sent',
        Check: '✅ Checked',
        Meeting: ' Meeting created',
        Quote: '🧾️ Quote provided',
        Invoice: '💲 Invoice created',
        Lead: '✔️ Lead created',
        Project: '✔️ Project created',
      };

      if (descriptions.hasOwnProperty(selectedOption)) {
        return descriptions[selectedOption];
      }

      return 'Description not available.';
    },
    getLogo(provider) {
      if (provider == 'Teams') {
        return require('@/assets/images/logo/teams.svg');
      } else if (provider == 'Zoom') {
        return require('@/assets/images/logo/zoom.svg');
      }
    },

    getShortVariant(selectedOption) {
      const variants = {
        Call: 'success',
        Mail: 'info',
        Check: 'danger',
        Meeting: 'primary',
        Quote: 'secondary',
        Invoice: 'dark',
        Lead: 'dark',
      };

      if (variants.hasOwnProperty(selectedOption)) {
        return variants[selectedOption];
      }

      return 'Description not available.';
    },

    toggleDescription(activity) {
      this.showFullDescription = !this.showFullDescription;
      activity.isOpening = !activity.isOpening;
    },

    toggleEditingMessage(activity) {
      if (activity.isEditingNotes == true) {
        store
          .dispatch('lead/updateActivityMessage', { activity: activity })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Activity note has been updated successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }

      activity.isEditingNotes = !activity.isEditingNotes;
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteLeadFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.leadData.get_files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.leadData.get_files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    saveFile(leadId) {
      if (this.percentCompleted == 100) {
        var data = {
          status: 'B',
          leadId: leadId,
          fileName: this.modalFileName,
          fileLink: this.modalFile,
        };
        // Trigger submit handler
        this.modalOk(data);
      }
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.formShow = true;

      axiosIns
        .post('saveLeadFile', data)
        .then((response) => {
          this.leadData.get_files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
          });

          this.modalFileName = null;
          this.modalFile = null;
          this.newFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadLeadFileUser', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },
  },
  watch: {
    selectedTime: 'calculateTimes',
    selectedTimezone: 'calculateTimes',
    selectedContact(value) {
      if (value == null) {
        this.leadData.firstName = null;
        this.leadData.lastName = null;
        this.leadData.email = null;
        this.leadData.phone = null;
      }
    },

    modalClient(value) {
      if (value != null) {
        this.modalClientName = value.name;
      } else {
        this.modalClientName = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#comment-section {
  padding: 20px;
}

.edit-comment {
  // padding: 2px;
  // border: 1px solid transparent;
  // transition: border 0.3s;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.edit-comment:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;

  border-color: #ccc;
}

.comment-info {
  flex-grow: 1;
}

.comment-time {
  color: #777;
  font-size: 0.8em;
  font-weight: 500;
  margin-right: 1em;
}

#activity-section {
  // background-color: #f8f9fa;
  padding: 20px;
}

#files-section {
  background-color: #f8f9fa;
  padding: 20px;
}

#time-section {
  padding-left: 20px;
  padding-right: 20px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment {
  margin-bottom: 5px;
}

.comment + .comment {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.activity + .activity {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

#comment-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more-comments-link {
  color: #007bff;
  cursor: pointer;
}

.more-comments-link:hover {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

#sidebar-lead-handler {
  position: relative;
}

.fix-comment {
  position: fixed;
  background-color: #f8f9fa;
  bottom: 0;
  width: 100%;
  padding: 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.attend-list {
  height: 210px;
  overflow-y: scroll;
}

.no-comment {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  color: #868e96;
  font-weight: bold;
}

.loader {
  text-align: center;
  color: #bbbbbb;
}

.multiselect__option:hover {
  background-color: transparent !important;
}

.meeting-row {
  display: flex;
  align-items: center; /* Dikey ortalama */
}

.meeting-label {
  float: right;
  font-weight: bold; /* Kalınlaştırma */
}

.logo-zoom {
  height: 30px;
  width: 30px;
}
.logo-teams {
  height: 25px;
  width: 25px;
}

.button-zoom {
  background-color: #0a5cfe !important;
  margin: 2%;
}
.button-teams {
  background-color: #464fb8 !important;
  margin: 2%;
}

.color-zoom {
  color: #0a5cfe !important;
}
.color-teams {
  color: #464fb8 !important;
}

.border-zoom {
  border: solid 1px #0a5cfe;
}
.border-teams {
  border: solid 1px #464fb8;
}
</style>

<style scoped>
.country-list {
  list-style-type: none;
  padding: 0;
}

.country-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  border-bottom: 1px solid #e0e0e0;
}

.country-item:hover {
  background-color: #777;
  cursor: pointer;
  color: white;
}

.dark-bg {
  /* background-color: #333;
  color: #fff;   */
  background-color: #f7f7f7;
}

.country-name {
  font-weight: bold;
  width: 40%;
}

.country-time {
  font-style: italic;
  width: 20%;
}

.country-timezone {
  font-size: 12px;
  width: 40%;
}

.flatpickr-sm {
  font-size: 0.875rem;
  height: 30px;
  background-color: white;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #007bff; /* Blue spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Slider (switch) özelleştirmesi */

.toggle {
  cursor: pointer;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.thumbs-up-down .toggle-btn::after {
  font-size: 1.5rem;
  content: '👎';
  margin-left: 1rem;
  margin-right: 1rem;
}

.thumbs-up-down .toggle-checkbox:checked + .toggle-btn::after {
  font-size: 1.5rem;
  content: '👍';
  margin-left: 1rem;
  margin-right: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
