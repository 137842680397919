<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div v-if="$Can('leads_add_btn')" class="add-task">
            <b-button @click="showLeadHandler" variant="primary" block>
              Add Lead
            </b-button>
          </div>
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area">
            <!-- Filters -->
            <div class="px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Sections
              </h6>
            </div>

            <!-- <b-list-group class="list-group-filters">
                <b-list-group-item v-for="filter in leadFilters" :key="filter.title + $route.path" :to="filter.route" :active="isDynamicRouteActive(filter.route)" @click="$emit('close-left-sidebar')">
                  <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                  <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                </b-list-group-item>
              </b-list-group> -->

            <b-list-group class="list-group-filters">
              <b-list-group-item :key="'All Sections'" :active="isDynamicRouteActive('leads-dashboard')" @click="navigateToDashboard" style="cursor:pointer" :class="{ 'text-danger': isDynamicRouteActive('leads-dashboard') }">
                <feather-icon icon="AlignJustifyIcon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">All Sections</span>
              </b-list-group-item>

              <draggable v-model="leadFilters" :options="dragOptions" @change="updateOrderNumbers">
                <b-list-group-item v-for="filter in leadFilters" :key="filter.title" :to="filter.route" :active="isDynamicRouteActive(filter.route)" :class="{ 'text-danger': isDynamicRouteActive(filter.route) }">
                  <feather-icon :icon="filter.icon" size="18" class="mr-75" />
                  <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                </b-list-group-item>
              </draggable>
            </b-list-group>

            <div v-if="$Can('leads_manage_sections')" class="px-2 d-flex justify-content-between" v-b-modal.modal-manage-section style="cursor:pointer">
              <h6 class="add-section-label mb-1 mt-50"><feather-icon icon="PlusIcon" size="18" class="mr-75" /> Manage Sections</h6>
            </div>

            <!-- <div class="px-2 d-flex justify-content-between" v-b-modal.modal-create-section style="cursor:pointer">
              <h6 class="add-section-label mb-1 mt-50"><feather-icon icon="PlusIcon" size="18" class="mr-75" /> Add Section</h6>
            </div> -->

            <!-- Tags -->
            <div class="mt-1 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Developer Teams
              </h6>
              <!-- <feather-icon icon="PlusIcon" /> -->
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item :key="'All Teams'" :active="isDynamicRouteActive('leads-dashboard')" @click="navigateToDashboard" style="cursor:pointer">
                <span class="bullet bullet-sm mr-1" :class="`bullet-secondary`" />
                <span>All Teams</span>
              </b-list-group-item>

              <b-list-group-item v-for="tag in leadTags" :key="tag.title + $route.path" :to="tag.route" :active="isDynamicRouteActive(tag.route)" @click="$emit('close-left-sidebar')">
                <span class="bullet bullet-sm mr-1" :class="`bullet-${tag.color}`" />
                <span>{{ tag.title }} </span>
              </b-list-group-item>
            </b-list-group>

            <div class="mt-1 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Statuses
              </h6>
              <!-- <feather-icon icon="PlusIcon" /> -->
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item :key="'All Statuses'" :active="isDynamicRouteActive('leads-dashboard')" @click="navigateToDashboard" style="cursor:pointer">
                <span
                  class="mb-50 button-container hoverable-button"
                  :style="{
                    backgroundColor: '#808080',
                    color: '#FFFFFF',
                    'font-size': '0.9em',
                    border: '1px solid #ccc',
                    'box-shadow': '0px 3px 7px rgba(0, 0, 0, 0.1)',
                    padding: '6px',
                    'border-radius': '0px',
                    cursor: 'pointer',
                    width: '100%',
                  }"
                >
                  All Statuses
                </span>
              </b-list-group-item>

              <b-list-group-item v-for="tag in leadStatuses" :key="tag.title + $route.path" :to="tag.route" :active="isDynamicRouteActive(tag.route)" @click="$emit('close-left-sidebar')">
                <!-- <span class="bullet bullet-sm mr-1" :class="`bullet-${tag.bgColor}`" />
                <span>{{ tag.title }} </span> -->

                <span
                  class="mb-50 button-container hoverable-button"
                  :style="{
                    backgroundColor: tag.bgColor,
                    color: tag.textColor,
                    'font-size': '0.9em',
                    border: '1px solid #ccc',
                    'box-shadow': '0px 3px 7px rgba(0, 0, 0, 0.1)',
                    padding: '6px',
                    'border-radius': '0px',
                    cursor: 'pointer',
                    width: '100%',
                  }"
                >
                  {{ tag.title }}
                </span>
              </b-list-group-item>
            </b-list-group>

            <div v-if="$Can('leads_manage_statuses')" class="px-2 d-flex justify-content-between" v-b-modal.modal-manage-status style="cursor:pointer">
              <h6 class="add-section-label mb-1 mt-50"><feather-icon icon="PlusIcon" size="18" class="mr-75" /> Manage Statuses</h6>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <add-section-modal @section-added="listSection" :leadFilters="leadFilters"></add-section-modal>
    <manage-section-modal @section-added="listSection" :leadFilters="leadFilters"></manage-section-modal>

    <add-status-modal @status-added="listStatus" :leadStatuses="leadStatuses"></add-status-modal>
    <manage-status-modal @status-added="listStatus" :leadStatuses="leadStatuses"></manage-status-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue';
import { isDynamicRouteActive } from '@core/utils/utils';
import Ripple from 'vue-ripple-directive';
import AddSectionModal from './AddSectionModal.vue';
import ManageSectionModal from './ManageSectionModal.vue';

import AddStatusModal from './AddStatusModal.vue';
import ManageStatusModal from './ManageStatusModal.vue';

import store from '@/store';
import draggable from 'vuedraggable';
import router from '@/router';

export default {
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    AddSectionModal,
    ManageSectionModal,
    AddStatusModal,
    ManageStatusModal,

    draggable,
  },
  directives: {
    Ripple,
  },

  setup() {
    return {
      isDynamicRouteActive,
    };
  },

  props: {
    leadTags: {
      type: Array,
      required: true,
    },
  },

  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    return {
      isHovered: false,
      leadFilters: [],
      leadStatuses: [],

      perfectScrollbarSettings,
      dragOptions: {
        animation: 200,
      },
    };
  },

  created() {
    this.listSection();
    this.listStatus();
  },

  methods: {
    showLeadHandler() {
      this.$emit('update:isLeadHandlerSidebarActive', true);
    },

    listSection() {
      this.loading = true;
      store
        .dispatch('lead/listSection')
        .then((res) => {
          this.leadFilters = [];

          res.data.forEach((element, index) => {
            this.leadFilters.push({ id: element.id, title: element.title, textColor: element.text_color, bgColor: element.bg_color, editing: false, newName: null, icon: 'MenuIcon', route: { name: 'leads-dashboard-filter', params: { filter: element.link } } });
          });

          this.$emit('update:leadFilters', this.leadFilters);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });

          console.log(error);
        });
    },

    listStatus() {
      this.loading = true;
      store
        .dispatch('lead/listStatus')
        .then((res) => {
          this.leadStatuses = [];

          res.data.forEach((element, index) => {
            this.leadStatuses.push({ id: element.id, editing: false, newName: null, title: element.title, textColor: element.text_color, bgColor: element.bg_color, icon: 'MenuIcon', route: { name: 'leads-dashboard-status', params: { status: element.title } } });
          });

          this.$emit('update:leadStatuses', this.leadStatuses);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });

          console.log(error);
        });
    },

    navigateToDashboard() {
      if (!this.isDynamicRouteActive('leads-dashboard')) {
        this.$router.push({ name: 'leads-dashboard' });
        this.$emit('navigate-dashboard');
      }
    },

    updateOrderNumbers(newOrder) {
      this.leadFilters.forEach((filter, index) => {
        filter.order_number = index + 1;
      });

      store
        .dispatch('lead/updateOrderNumbers', this.leadFilters)
        .then((res) => {})
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ There was an error updating the order numbers. Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.add-section-label {
  color: rgb(148, 148, 148);
}

.add-section-label:hover {
  color: rgb(88, 88, 88);
}

.all-section-label {
  color: #4e5154;
}

.all-section-label:hover {
  color: #73c0d2;
}

.list-group-item {
  transition: all 1s;
}
</style>
